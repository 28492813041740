@import 'variables';

@mixin theming($name, $color) {
  .theme-#{$name} {
    .bs-datepicker-head {
      background-color: #5780ff;
    }

    .bs-datepicker-body {
      table {
        td {
          span.selected,
          &.selected span,
          span[class*='select-']:after,
          &[class*='select-'] span:after {
            background-color: #5780ff;
          }

          &.week span {
            color: #5780ff;
          }

          &.active-week span:hover {
            cursor: pointer;
            background-color: #5780ff;
            color: $font-color-01;
            opacity: 0.5;
            transition: 0s;
          }
        }
      }
    }
  }
}
